import React from "react"
import { ReactChildrenProps } from "../../types/ReactChildrenProps"

function getAnchorName(content: React.ReactNode): string | undefined {
  if (typeof content !== "string") {
    return
  }

  return `${content.replace(/\s+/g, "-").toLowerCase()}`
}

interface AnchorProps extends ReactChildrenProps {
  name?: string
}

const Anchor: React.FC<AnchorProps> = ({ name, children }) => {
  const anchorName = name ?? getAnchorName(children)

  return (
    <a id={anchorName} className="hover:underline" href={`#${anchorName}`}>
      {children}
    </a>
  )
}

const headingClasses = "font-rocgrotesk text-midnight-sky dark:text-white leading-none first:mt-0 mt-9 mb-3 break-words"

export interface HeadingProps extends ReactChildrenProps {
  unanchored?: boolean
  anchor?: string
}

export const HeadingOne: React.FC<HeadingProps> = ({ children, anchor, unanchored }) => {
  return (
    <h1 className={`${headingClasses} font-bold text-[24px] lg:text-[48px]`}>
      {unanchored ? children : <Anchor name={anchor}>{children}</Anchor>}
    </h1>
  )
}

export const HeadingTwo: React.FC<HeadingProps> = ({ children, anchor, unanchored }) => {
  return (
    <h2 className={`${headingClasses} font-bold lg:font-medium text-[18px] lg:text-[32px]`}>
      {unanchored ? children : <Anchor name={anchor}>{children}</Anchor>}
    </h2>
  )
}

export const HeadingThree: React.FC<HeadingProps> = ({ children, anchor, unanchored }) => {
  return (
    <h3 className={`${headingClasses} font-medium text-[18px] lg:text-[24px]`}>
      {unanchored ? children : <Anchor name={anchor}>{children}</Anchor>}
    </h3>
  )
}

export const HeadingFour: React.FC<HeadingProps> = ({ children }) => {
  return <h4 className={`${headingClasses} font-medium text-[16px] lg:text-[18px]`}>{children}</h4>
}

export const HeadingFive: React.FC<HeadingProps> = ({ children }) => {
  return <h5 className={`${headingClasses} font-medium text-[14px] lg:text-[16px]`}>{children}</h5>
}

export const HeadingSix: React.FC<HeadingProps> = ({ children }) => {
  return <h6 className={`${headingClasses} font-medium text-[14px] lg:text-[16px]`}>{children}</h6>
}

export const Paragraph: React.FC<ReactChildrenProps> = ({ children }) => {
  return <span className="font-normal text-base lg:text-lg mb-3">{children}</span>
}
