import React from "react"
import { PageProps } from "gatsby"

import PageMetadata from "../../components/page/page-metadata"
import DocumentationLayout from "../../components/documentation/documentation-layout"
import { HeadingOne } from "../../components/common/typography"
import { useContentSections } from "../../hooks/content-sections"
import { ContentSectionGridList } from "../common/content-section-grid-list"

type PageContextProps = {
  sectionPath: string
}

const SectionLandingPage: React.FC<PageProps<object, PageContextProps>> = ({ pageContext: { sectionPath }, location }) => {
  const { getContentForPath } = useContentSections()
  const content = getContentForPath(sectionPath)

  if (!content) {
    return null
  }

  const section = content.sections[content.sections.length - 1]

  return (
    <div className="mx-6 md:mx-12 xl:mx-20">
      <PageMetadata title={section.title} />
      <DocumentationLayout path={location.pathname}>
        <HeadingOne unanchored>{section.title}</HeadingOne>
        <div className="font-normal text-base lg:text-lg">{section.synopsis}</div>

        <ContentSectionGridList sectionPath={sectionPath} />
      </DocumentationLayout>
    </div>
  )
}

export default SectionLandingPage
