import { Link } from "gatsby"
import React from "react"

import { useContentSections } from "../../hooks/content-sections"

type Props = {
  path: string
}

const BreadcrumbLinks: React.FC<Props> = ({ path }) => {
  const { getContentForPath } = useContentSections()
  const content = getContentForPath(path)

  if (!content || (content.sections.length === 1 && !content.article && !content.page)) {
    return null
  }

  const { sections, article, page } = content

  const links = sections.map(({ title, path }, index) => (
    <BreadcrumbLink key={path} title={title} path={path} active={index === sections.length - 1 && !article && !page} />
  ))

  if (article != null) {
    const { title, path } = article

    links.push(<BreadcrumbLink key={path} title={title} path={path} active />)
  }

  if (page != null) {
    const { title, path } = page

    links.push(<BreadcrumbLink key={path} title={title} path={path} active />)
  }

  return (
    <div className="flex flex-row items-center font-rocgrotesk text-lg mb-9">
      {links.map((link, index) => (
        <React.Fragment key={index}>
          {link} {index < links.length - 1 ? <div className="mx-2">/</div> : null}
        </React.Fragment>
      ))}
    </div>
  )
}

type BreadcrumbLinkProps = {
  path: string
  title: string
  active?: boolean
}

const BreadcrumbLink: React.FC<BreadcrumbLinkProps> = React.memo<BreadcrumbLinkProps>(function BreadcrumbLink({ path, title, active }) {
  return (
    <Link to={path} className={active ? "font-medium hover:underline" : "font-normal underline"}>
      {title}
    </Link>
  )
})

export default BreadcrumbLinks
