import React from "react"
import { ReactChildrenProps } from "../../types/ReactChildrenProps"

import BreadcrumbLinks from "./breadcrumb-links"

interface Props extends ReactChildrenProps {
  path: string
}

const DocumentationLayout: React.FC<Props> = ({ path, children }) => {
  return (
    <div className="flex flex-col py-6 lg:py-[90px] max-w-[1440px] mx-auto">
      <BreadcrumbLinks path={path} />
      {children}
    </div>
  )
}

export default DocumentationLayout
